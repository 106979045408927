import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/product/Category';
import { Link, Column as FooterColumn } from 'components/commercetools-ui/footer/column';
import Typography from 'components/commercetools-ui/typography';
import AmericanExpress from 'components/icons/payment-methods/american-express';
import MasterCard from 'components/icons/payment-methods/mastercard';
import Paypal from 'components/icons/payment-methods/paypal';
import Visa from 'components/icons/payment-methods/visa';
import { useFormat } from 'helpers/hooks/useFormat';
import { ReferenceLink } from 'helpers/reference';
export interface Props {
  column: FooterColumn;
  categories: Category[];
  copyright?: string;
  copyrightLinks?: Link[];
}

const paymentMethods = [
  { name: 'paypal', link: 'https://www.paypal.com', icon: <Paypal /> },
  { name: 'visa', link: 'https://www.visa.com', icon: <Visa /> },
  { name: 'mastercard', link: 'https://www.mastercard.com', icon: <MasterCard /> },
  { name: 'american-express', link: 'https://www.americanexpress.com', icon: <AmericanExpress /> },
];

const Footer: React.FC<Props> = ({ column, categories, copyright, copyrightLinks }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <footer className="relative z-10 bg-white pt-12 lg:pt-[80px]">
      <div className="container mx-auto max-w-[1280px] px-4 sm:px-10 lg:px-4">
        <div className=" flex flex-wrap lg:mx-0">
          <div className="w-full sm:w-2/3 lg:w-3/12">
            <div className="mb-10 w-full">
              <a href="/" className="mb-4 inline-block max-w-[120px]">
                <img src="/images/zyres-logo.svg" alt="logo" className="max-w-full" />
              </a>
              <div className="leading-1 flex items-center text-base font-medium text-gray-100 hover:text-black-100">
                <span className="mr-4 text-black-100">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.4546 14.6669H10.5325C9.88316 14.6669 9.34203 14.1256 9.34203 13.476V11.094C9.34203 10.8559 9.14723 10.661 8.90913 10.661H7.11259C6.8745 10.661 6.67969 10.8559 6.67969 11.094V13.4543C6.67969 14.1039 6.13857 14.6453 5.48922 14.6453H2.52385C1.8745 14.6453 1.33337 14.1039 1.33337 13.4543V5.89713C1.33337 5.57232 1.50653 5.26917 1.78792 5.09594L7.5022 1.47975C7.80524 1.28487 8.21649 1.28487 8.51952 1.47975L14.2338 5.09594C14.5152 5.26917 14.6667 5.57232 14.6667 5.89713V13.4327C14.6667 14.1256 14.1256 14.6669 13.4546 14.6669ZM7.09095 9.90308H8.88749C9.53684 9.90308 10.078 10.4444 10.078 11.094V13.4543C10.078 13.6925 10.2728 13.8874 10.5109 13.8874H13.4546C13.6927 13.8874 13.8875 13.6925 13.8875 13.4543V5.91878C13.8875 5.85382 13.8442 5.78886 13.8009 5.74555L8.10827 2.12937C8.04333 2.08606 7.95675 2.08606 7.91346 2.12937L2.19917 5.74555C2.13424 5.78886 2.11259 5.85382 2.11259 5.91878V13.476C2.11259 13.7142 2.3074 13.909 2.5455 13.909H5.48922C5.72731 13.909 5.92212 13.7142 5.92212 13.476V11.094C5.90047 10.4444 6.4416 9.90308 7.09095 9.90308Z"
                      fill="#2A5967"
                    />
                  </svg>
                </span>
                <p className="max-w-[230px] font-urbanist text-base font-medium">
                  Stuttgarter strasse 25, 60329 Frankfurt am Main, Germany
                </p>
              </div>
            </div>
          </div>

          <LinkGroup header={column.header}>
            {column.links.map((item, i) => (
              <li key={i}>
                <ReferenceLink
                  target={item.reference}
                  className={`mb-2 inline-block font-urbanist text-base font-medium leading-loose text-gray-100 hover:text-primary`}
                >
                  <Typography>{item.name}</Typography>
                </ReferenceLink>
              </li>
            ))}
          </LinkGroup>

          <LinkGroup header={formatMessage({ id: 'shop.departments', defaultMessage: 'Shop Departments' })}>
            {categories.map((item, i) => (
              <li key={i}>
                <NextLink href={item._url}>
                  <a
                    className={`mb-2 inline-block font-urbanist text-base font-medium leading-loose text-gray-100 hover:text-primary`}
                  >
                    <Typography>{item.name}</Typography>
                  </a>
                </NextLink>
              </li>
            ))}
          </LinkGroup>

          <div className="w-full sm:w-1/2 md:px-0 lg:w-3/12">
            <div className="mb-8 w-full">
              <div>
                <h4 className="mb-6 font-urbanist text-lg font-semibold text-black-100">
                  {formatMessage({ id: 'download.app', defaultMessage: 'Download App' })}
                </h4>
                <div className="flex w-full flex-col sm:w-[230px]">
                  <a
                    href="/#"
                    className="mr-3 mb-3 flex w-full items-center justify-center rounded bg-primary px-2 py-3 hover:bg-opacity-90"
                  >
                    <span className="block pr-3">
                      <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M21.76 15.854C21.7452 13.3949 22.8884 11.5416 25.1971 10.1751C23.9059 8.37227 21.9524 7.38072 19.3772 7.18962C16.939 7.00213 14.2714 8.57419 13.2947 8.57419C12.2624 8.57419 9.90189 7.25453 8.04456 7.25453C4.21149 7.31222 0.137939 10.2328 0.137939 16.1749C0.137939 17.9308 0.467228 19.7444 1.1258 21.6121C2.00637 24.0712 5.18086 30.0962 8.49224 29.9988C10.2238 29.9592 11.4484 28.8018 13.7017 28.8018C15.8883 28.8018 17.0204 29.9988 18.9518 29.9988C22.2927 29.952 25.1638 24.475 26 22.0088C21.5195 19.9499 21.76 15.9801 21.76 15.854ZM17.8714 4.85678C19.7472 2.68619 19.577 0.710309 19.5215 0C17.864 0.0937464 15.9475 1.09972 14.856 2.33645C13.6536 3.66332 12.9469 5.30388 13.0986 7.15357C14.8893 7.28698 16.5247 6.38917 17.8714 4.85678Z"
                          fill="white"
                        ></path>
                      </svg>
                    </span>
                    <span className="font-urbanist text-sm font-medium text-white">
                      {formatMessage({ id: 'download.apple', defaultMessage: 'Download on the' })}
                      <span className="block font-urbanist font-semibold"> Apple App Store </span>
                    </span>
                  </a>
                  <a
                    href="/#"
                    className="mr-3 flex w-full items-center justify-center rounded bg-black-200 px-2 py-3 hover:bg-opacity-90"
                  >
                    <span className="block pr-3">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M24.0726 9.87928L5.04416 0.38688C3.93691 -0.185937 2.66562 -0.104106 1.64038 0.509627C0.615142 1.16428 0 2.26899 0 3.49646V22.4813C0 23.7087 0.615142 24.8135 1.68139 25.4681C2.25552 25.8363 2.87066 26 3.52681 26C4.05994 26 4.59306 25.8773 5.08517 25.6318L24.0726 16.0984C25.2618 15.4847 26 14.2982 26 12.9889C26 11.6796 25.2618 10.4521 24.0726 9.87928ZM15.2555 7.05611L11.5647 11.8023L3.4858 1.40977C3.81388 1.40977 4.10095 1.4916 4.38801 1.61435L15.2555 7.05611ZM1.43533 22.4813V3.49646C1.43533 2.92364 1.68139 2.35083 2.13249 1.98259L10.6625 12.9889L2.09148 23.9951C1.64038 23.6269 1.43533 23.0541 1.43533 22.4813ZM4.38801 24.3225C4.10095 24.4861 3.77287 24.527 3.44479 24.527L11.5237 14.1345L15.2145 18.9216L4.38801 24.3225ZM23.4574 14.8301L16.5678 18.267L12.4669 12.9889L16.5678 7.71076L23.4574 11.1477C24.1546 11.5159 24.6057 12.2115 24.6057 12.9889C24.6057 13.7663 24.1546 14.4618 23.4574 14.8301Z"
                          fill="white"
                        ></path>
                      </svg>
                    </span>
                    <span className="font-urbanist text-sm font-medium text-white">
                      <span className="uppercase">
                        {formatMessage({ id: 'download.android', defaultMessage: 'Get it on' })}
                      </span>
                      <span className="block font-urbanist font-semibold"> Google Play Store </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-4 bg-[#F6F9FF] pb-10 pt-6 md:pt-10 md:pb-20">
        <div className="flex items-center justify-center gap-6">
          {paymentMethods.map((paymentMethod) => (
            <a href={paymentMethod.link} key={paymentMethod.name} target="_blank" rel="noreferrer">
              {paymentMethod.icon}
            </a>
          ))}
        </div>
        <p className="font-urbanist text-base font-medium text-[#808080]">
          © {new Date().getFullYear()} ZYRES. {copyright}
        </p>
      </div>
    </footer>
  );
};

export default Footer;

const LinkGroup = ({ children, header }: { children: ReactNode; header: string }) => {
  return (
    <div className="w-1/2 pr-2 lg:w-3/12">
      <div className="mb-10 w-full">
        <h4 className="mb-4 font-urbanist text-lg font-semibold text-black-100"> {header} </h4>
        <ul>{children}</ul>
      </div>
    </div>
  );
};
