import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import nextRouter, { useRouter } from 'next/router';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { Category } from '@Types/product/Category';
import { MiddlewareOptions } from 'instantsearch.js';
import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import { Configure, ConfigureProps, InstantSearch, useConfigure, useInstantSearch } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import Breadcrumb from 'components/commercetools-ui/breadcrumb';
import ChevronDown from 'components/icons/chevron-down';
import { FilterList, SideContent } from 'components/tailgrids-ui/products/product-filter';
import { ProductView, ProductViewSwitch } from 'components/tailgrids-ui/products/product-list';
import {
  TypesenseProps,
  Hits,
  TypesenseSearchInput,
  Filter,
  PriceRangeSlider,
  SortByDropdown,
  ResetFiltersButton,
  ToggleFilter,
} from 'features/typesense/components';
import {
  getTypesenseInstantSearchAdapter,
  TYPESENSE_ANY,
  TYPESENSE_COLLECTION_PRODUCTS,
  TYPESENSE_LABEL_PREFIX,
} from 'features/typesense/utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocalStorage } from 'helpers/hooks/useLocalStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import { queryCategory, getCategoryFull, getVersion } from 'frontastic/actions/products';
import { InstantSearchFallback } from './InstantSearchFallback';
import { FullPageWidthWrapper } from 'components/tailgrids-ui/full-bleed';
import { useQuery, gql } from '@apollo/client';
import { sections } from '../../../graphql/strapi/sections';
import { ContactForm } from 'components/tailgrids-ui/contact-form';
import { ComponentMapper } from 'components/tailgrids-ui/strapi/ComponentMapper';

export const newCategoryId = 'f397e6a1-59ba-419d-8f69-c7c7174d5696';
export const saleCategoryId = 'cddeb94a-58d2-4d09-9b3d-159f326fe850';

const isNewInCategories = (categories: string[]) => {
  return categories?.includes('new') || categories?.includes('neu') || categories?.includes('nouveau');
};

const isSaleInCategories = (categories: string[]) => {
  return categories.includes('sale') || categories?.includes('vente');
};

export type OpenedState = 'filter' | 'sort' | 'none';

type CategoryLink = { name: string; slug: string; parent: boolean };

export const CustomInstantSearch = ({ data, selectedCategory, locale }: TypesenseProps) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const isCategory = data?.isCategory as boolean;
  const { account, loggedIn } = useAccount();

  const language = locale.split('-')[0];
  const country = locale.split('-')[1];
  const customerGroup = account?.customerGroup?.id ?? TYPESENSE_ANY;
  const priceCountry = country;
  const priceGroup = customerGroup;

  const additionalSortBy = ',in_stock:desc';
  const defaultSortByValue = `${TYPESENSE_COLLECTION_PRODUCTS}/sort/attributes.relevance:desc${additionalSortBy}`;

  const sortByMaps: {
    id: string;
    defaultMessage: string;
    value: string;
  }[] = [
    {
      id: 'relevance',
      defaultMessage: 'Relevancy',
      value: defaultSortByValue,
    },
    {
      id: 'priceAsc',
      defaultMessage: 'Price (asc)',
      value: `${TYPESENSE_COLLECTION_PRODUCTS}/sort/price.${priceCountry}.${priceGroup}.cent_amount:asc${additionalSortBy}`,
    },
    {
      id: 'priceDesc',
      defaultMessage: 'Price (desc)',
      value: `${TYPESENSE_COLLECTION_PRODUCTS}/sort/price.${priceCountry}.${priceGroup}.cent_amount:desc${additionalSortBy}`,
    },
  ];

  const sortByItems: SortByItem[] = sortByMaps.map((item) => ({
    label: formatMessage({ id: item.id, defaultMessage: item.defaultMessage }),
    value: item.value,
  }));

  const filters = [
    'published:true',
    ...(!loggedIn || !account?.customerGroup
      ? [`attributes.customer_group_target:=ANY`]
      : account?.customerGroup
      ? [`attributes.customer_group_target:=${account.customerGroup.id} || attributes.customer_group_target:=ANY`]
      : []),
    ...(isCategory && selectedCategory ? [`categories.${language}:=${selectedCategory}`] : []),
  ];

  const instantSearchAdapter = useCallback(
    () =>
      getTypesenseInstantSearchAdapter({
        exclude_fields: undefined,
        include_fields: `product_id,names,slugs,descriptions,sku,attributes,price.${priceCountry},category_ids,image_urls,in_stock`,
        per_page: 24,
      }),
    [priceCountry],
  );

  return (
    <InstantSearch
      indexName={defaultSortByValue}
      searchClient={instantSearchAdapter().searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      routing={{
        router: createInstantSearchRouterNext({
          singletonRouter: nextRouter,
        }),
      }}
    >
      <Head>
        {!isCategory && (
          <title>{formatMessage({ id: TYPESENSE_LABEL_PREFIX + 'title', defaultMessage: 'Product search' })}</title>
        )}
      </Head>
      <Configure filters={filters.join(' && ')} />
      <Middleware category={isCategory ? selectedCategory : ''}>
        <InstantSearchContent
          isCategory={isCategory}
          sortByItems={sortByItems}
          priceCountry={priceCountry}
          priceGroup={priceGroup}
          selectedCategory={selectedCategory}
          language={language}
        />
      </Middleware>
    </InstantSearch>
  );
};

const InstantSearchContent = ({
  isCategory,
  sortByItems,
  selectedCategory,
  priceCountry,
  priceGroup,
  language,
}: {
  isCategory: boolean;
  sortByItems: SortByItem[];
  selectedCategory?: string;
  priceCountry: string;
  priceGroup: string;
  language: string;
}) => {
  const [productView, setProductView] = useLocalStorage<ProductView>('product_view', 'grid');
  const [showMobile, setShowMobile] = useState<OpenedState>('none');
  const [breadcrumbSlug, setBreadcrumbSlug] = useState<[string, string]>(['', '']);
  const [categoryLinks, setCategoryLinks] = useState<CategoryLink[]>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const { account } = useAccount();

  const [width] = useMediaQuery();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });

  const showCharacters = useCallback((str: string) => {
    const mapChar = {
      U2D: '-',
      U20: ' ',
      U26: ' & ',
    };
    return str.replace(/(?:U2D|U20|U26)/gi, (matched) => mapChar[matched]);
  }, []);

  const filterClassName = useCallback(
    (open: OpenedState) =>
      'absolute inset-x-0 top-0 z-10 flex origin-top bg-white transition-transform lg:static ' +
      (showMobile === open ? 'scale-y-100' : 'scale-y-0 lg:scale-y-100'),
    [showMobile],
  );

  useEffect(() => {
    const fetchCategory = async () => {
      const category = (await queryCategory(selectedCategory, language)) as Category;
      setBreadcrumbSlug([
        category?.breadcrumbNavigation?.[language] !== ''
          ? category?.breadcrumbNavigation?.[language]
          : category?.slugs?.[language] ?? '',
        category?.slugs?.[language] ?? '',
      ]);

      setCategoryId(category.id);

      const categoryToLink = (category, parent = false): CategoryLink => {
        return {
          name: category?.nameAllLocales?.find((name) => name?.locale === language)?.value as string,
          slug: category?.slugAllLocales?.find((name) => name?.locale === language)?.value as string,
          parent,
        };
      };

      const categoryFull = await getCategoryFull(category.id);
      setCategoryLinks([
        ...(categoryFull?.parent ? [categoryToLink(categoryFull.parent, true)] : []),
        ...(categoryFull?.children ? categoryFull.children.map(categoryToLink) : []),
      ]);
    };

    if (isCategory) {
      fetchCategory().then();
    }
  }, [isCategory, language, selectedCategory]);

  const GET_PAGES = gql`
    query Content($categoryId: String) {
      categoryContents(filters: { categoryId: { eq: $categoryId } }, locale: "${router.locale}") {
        data {
          id
          attributes {
            __typename
            locale
            name
            categoryId
            body {
              __typename
              ...S1Banner
              ...S4ProductList
              ...S3SingleProduct
              ...S55Form
              ...Cz12TextBlock
              ...Cz10Accordion
              ...Cz15Article
              ...Cz11Cta
              ...Cz13ImageWithText
              ...CzAboutUs
              ...Cz16Features
              ...Cz14ImageGallery
            }
          }
        }
      }
    }
    ${sections}
  `;

  const { loading, error, data } = useQuery(GET_PAGES, {
    variables: { categoryId: categoryId },
  });

  return (
    <FullPageWidthWrapper>
      <div className="container mx-auto max-w-[1280px] px-4 py-10 sm:px-10 lg:px-4">
        {isCategory && (
          <Breadcrumb Separator={'/'} SearchIcon={false} className="ml-2 mb-2">
            {breadcrumbSlug?.[0].split('-').map((item, idx) => {
              const itemValue = showCharacters(item);
              return (
                <Link
                  href={breadcrumbSlug?.[1]
                    .split('-')
                    .slice(0, idx + 1)
                    .join('-')}
                  key={`${item}-${idx}`}
                >
                  {formatMessage({ id: TYPESENSE_LABEL_PREFIX + itemValue.toLowerCase(), defaultMessage: itemValue })}
                </Link>
              );
            })}
          </Breadcrumb>
        )}
        {data?.categoryContents?.data[0]?.attributes?.body.map((component) => {
          if (
            component?.customerGroups?.length > 0 &&
            !component?.customerGroups?.includes(account?.customerGroup?.id)
          ) {
            return null;
          }
          if (component.__typename === 'ComponentSectionsS55Form') {
            return (
              <ContactForm
                formName={component.formName}
                submitText={component.submitText}
                fields={component.fields}
                id={component.id}
                pageId={component.id}
                key={`${component.__typename}-${component.id}`}
              />
            );
          } else {
            return <ComponentMapper key={`${component.__typename}-${component.id}`} component={component} />;
          }
        })}
        {!isCategory && (
          <div className="flex w-full flex-row justify-center">
            <TypesenseSearchInput
              placeholder={formatMessage({
                id: TYPESENSE_LABEL_PREFIX + 'inputPlaceholder',
                defaultMessage: 'Search for products...',
              })}
              data-testid="searchInput"
            />
          </div>
        )}
        {/*<div className="grid grid-cols-4 bg-slate-100">*/}
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-10 lg:gap-12">
          <div className="mt-4 flex w-full items-start justify-between lg:hidden">
            <div
              className="flex cursor-pointer select-none items-center gap-2 rounded border border-neutral-200 px-4 py-1"
              onClick={() => setShowMobile((prev) => (prev === 'filter' ? 'none' : 'filter'))}
            >
              {formatMessage({ id: 'filter', defaultMessage: 'Filter' })}
              <ChevronDown
                className={'transition-transform ' + (showMobile === 'filter' ? 'rotate-180' : 'rotate-0')}
              />
            </div>
            <div
              className="flex cursor-pointer select-none items-center gap-2 rounded border border-neutral-200 px-4 py-1"
              onClick={() => setShowMobile((prev) => (prev === 'sort' ? 'none' : 'sort'))}
            >
              {formatMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
              <ChevronDown className={'transition-transform ' + (showMobile === 'sort' ? 'rotate-180' : 'rotate-0')} />
            </div>
          </div>
          <div className="relative col-span-3 md:pt-[50px]">
            <FilterList className={filterClassName('sort')}>
              <SideContent title={formatMessage({ id: 'sortBy', defaultMessage: 'Sort by' })} key="sortingPages">
                <div className="flex flex-wrap">
                  <SortByDropdown data-testid="sortByDropdown" items={sortByItems} />
                  <ToggleFilter
                    data-testid="toggleInStock"
                    attribute="in_stock"
                    label="label"
                    on="true"
                    off="true`, `false"
                    className="my-2 mt-1"
                  />
                </div>
              </SideContent>
              {width < desktop ? (
                <SideContent title={formatMessage({ id: 'view', defaultMessage: 'View' })}>
                  <ProductViewSwitch
                    value={productView}
                    onChange={(newView) => setProductView(newView)}
                    data-testid="productViewSwitch"
                    justify="start"
                  />
                </SideContent>
              ) : undefined}
            </FilterList>
            <FilterList
              className={filterClassName('filter')}
              filters={[
                isCategory && categoryLinks && categoryLinks.length > 0 ? (
                  <SideContent
                    key="categories"
                    title={formatMessage({ id: TYPESENSE_LABEL_PREFIX + 'categories', defaultMessage: 'Categories' })}
                  >
                    <div className="flex flex-col items-start justify-start gap-1">
                      {categoryLinks
                        ?.filter((item) => item !== undefined)
                        ?.map((category) => (
                          <Link key={category?.slug} href={`/${category?.slug}`} passHref>
                            <a className="flex flex-row items-center justify-start gap-1">
                              {category?.parent === true && <ArrowLeftIcon height={16} width={16} />}
                              {category?.name}
                            </a>
                          </Link>
                        ))}
                    </div>
                  </SideContent>
                ) : undefined,
                <PriceRangeSlider
                  data-testid="priceRangeSlider"
                  key="priceRange"
                  attribute={`price.${priceCountry}.${priceGroup}.cent_amount`}
                  precision={2}
                />,
                <Filter
                  data-testid="featuresFilter"
                  key="featuresFilter"
                  attribute="category_ids"
                  limit={100}
                  operator="and"
                  transformItems={(items) => {
                    return items.map((item) => {
                      if (item.value.includes(saleCategoryId) && !isSaleInCategories([selectedCategory])) {
                        item.label = formatMessage({ id: 'sale', defaultMessage: 'Sale' });
                      } else if (item.value.includes(newCategoryId) && !isNewInCategories([selectedCategory])) {
                        item.label = formatMessage({ id: 'new', defaultMessage: 'New' });
                      } else {
                        item.label = '';
                      }
                      return item;
                    });
                  }}
                />,
                <Filter
                  data-testid="colorSelector"
                  key="attributeColor"
                  attribute="attributes.color"
                  limit={10}
                  showMore
                  showMoreLimit={50}
                  transformItems={(items) => items.sort((a, b) => (a.label > b.label ? 1 : -1))}
                />,
                <Filter
                  data-testid="sizeSelector"
                  key="attributeSize"
                  attribute="attributes.size"
                  limit={10}
                  showMore
                  showMoreLimit={50}
                  transformItems={(items) => items.sort((a, b) => (a.label > b.label ? 1 : -1))}
                />,
                <Filter
                  data-testid="genderSelector"
                  key="attributeGender"
                  attribute="attributes.gender"
                  limit={10}
                  transformItems={(items) => items.sort((a, b) => (a.label > b.label ? 1 : -1))}
                />,
                <Filter
                  data-testid="styleSelector"
                  key="attributeStyle"
                  attribute="attributes.style"
                  limit={10}
                  transformItems={(items) => items.sort((a, b) => (a.label > b.label ? 1 : -1))}
                />,
                <Filter
                  data-testid="designerSelector"
                  key="attributeDesigner"
                  attribute="attributes.designer"
                  limit={10}
                  showMore
                  showMoreLimit={50}
                  transformItems={(items) => items.sort((a, b) => (a.label > b.label ? 1 : -1))}
                />,
                <ResetFiltersButton data-testid="resetFilters" key="resetFilters" />,
              ]}
            />
          </div>
          <div className="col-span-7">
            {width >= desktop && (
              <ProductViewSwitch
                value={productView}
                onChange={(newView) => setProductView(newView)}
                data-testid="productViewSwitch"
              />
            )}
            <Hits
              locale={router.locale}
              priceCountry={priceCountry}
              priceGroup={priceGroup}
              view={productView}
              showMobile={showMobile}
              className={
                'transition-all ' +
                (showMobile === 'none' ? 'opacity-100 blur-none' : 'opacity-75 blur-sm lg:opacity-100 lg:blur-0')
              }
              {...(showMobile !== 'none' && width < desktop
                ? {
                    onClick: (e) => {
                      e.preventDefault();
                      setShowMobile('none');
                    },
                  }
                : {})}
            />
          </div>
        </div>
      </div>
    </FullPageWidthWrapper>
  );
};

const Middleware = ({ children, category }: { children?: React.ReactNode; category?: string }) => {
  const { addMiddlewares } = useInstantSearch();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const middleware = ({ instantSearchInstance }: MiddlewareOptions) => {
      function handleError(searchError) {
        console.log(searchError);
        setError(searchError);
      }

      return {
        subscribe() {
          instantSearchInstance.addListener('error', handleError);
        },
        unsubscribe() {
          instantSearchInstance.removeListener('error', handleError);
        },
      };
    };

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  if (!error) {
    return <>{children}</>;
  }

  return (
    <>
      {/*<div className="text-red-700">{error.toString()}</div>*/}
      <InstantSearchFallback category={category} />
    </>
  );
};
